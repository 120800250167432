import ssgCrypto from "ssg.crypto";

import base64 from "../common/utils/base64.js";
//import dataUriLoader from "./crypto/data.uri.loader.js";

ssgCrypto.disableFakeSource();
let host = "https://kryptos.secserv.me";
console.warn("kryptos config included");

let config = {
	apiBase: host,
	socketBase: host,
	defaultTokenMode: 1,
	identifierByteSize: 32,
	pushNotifications: {
		"android": {
			senderID: "296310413136"
		},
		ios: {
			senderID: "296310413136",
			gcmSandbox: "true",
			alert: "true",
			badge: true,
			sound: "true"
		}
	},
	appId: "pgm",
	updateSignatures: [],
	defaultEncryptionConfig: {
		cipherAlgorithm: "aes-256-cbc",
		kdfPassword: "scrypt",
		kdfPasswordParam: {
			N: 3, //1<<3=8
			r: 8
		},
		kdfKeyed: "pbkdf2",
		kdfKeyedParam: {
			c: 7,
			klass: "HMAC_SHA256"
		},
		signatureAlgorithm: "ed25519",
		signatureParam: null,
		keyExchange: "dh25519",
		keyExchangeParam: null,
		hmacAlgorithm: "hmac-sha-256"
	},
	contactRecordCount: 1024,
	transactionRetryDelay: 2000,
	networkTimeout: 10000,
	helperContactToken: "w4akalyngvs6qhunf59kukyn2v9mtcyntn9pak5nt44s1x161umy0c9srsjmvys4us3mam64vscakn7kfz8mwhwzsjpzh0v5atgc4pjawftl0zte1575em58y1xx0rczhq7re3j2cl7xngu0c11",
	masterKeyReseedCount: 9,
	isHashConnectionIds: true,
	testUtils: true,
	defaultAutocleanTime: 365.25 * 24 * 3600,
	// showWorkgroups: true,
	buildMode: "demo",
	// useVirtualKeyboard: false,
	// demoMode: 7 * 24 * 3600000,
	// userExpiration: true,
	// privateServer: {
	// 	minAdmins: 2,
	// 	recommendedAdmins: 3,
	// 	maxPrivileged: 1500,
	// 	maxUsers: 100000
	// }
};

export default config;
