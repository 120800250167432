'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var mergeClassNames = function mergeClassNames() {
  for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return args.reduce(function (classList, arg) {
    return typeof arg === 'string' || arg instanceof Array ? classList.concat(arg) : classList;
  }, []).filter(function (className) {
    return className;
  }).join(' ');
};

exports.default = mergeClassNames;