import Rx from "rx";
import _ from "lodash";
import ssgCrypto, {Key, KEY_KINDS} from "ssg.crypto";
import {MESSAGE_TYPE} from "../models/chat.message.js";

import ClientServerRPCObservables from "./client.server.rpc.observables.js";
import configuration from "../../common/configuration.js";

import {
	deserializeObjectWithKeysFromManagedBufferAsync,
	deserializeObject
} from "../../common/serializer.js";

function createSigner( macKey ) { //TODO: move to module
	return {
		getSignByteLength: index => 32,
		makeSignThen: ( { getBufferThen } ) =>
			getBufferThen()
				.then( buffer =>
					ssgCrypto.makeHmacCodeThen(
						macKey,
						buffer,
						configuration.getDefaultEncryptionConfig()
					)
				)
	};
}

function createVerifier( macKey ) {
	return {
		getSignByteLength: index => 32,
		verifyThen: ( { signature, binarySource } ) => {
			const keyHandle = macKey.addKeyUse('createVerifier');
			return (
				binarySource.getBufferThen()
					.then( buffer =>
						ssgCrypto.makeHmacCodeThen(
							macKey,
							buffer,
							configuration.getDefaultEncryptionConfig()
						)
					)
					.then( code => {
						macKey.removeKeyUse(keyHandle);
						return code.equals( signature );
					} )
			);
		}
	};
}

function mapContactInList( contact ) {
	if ( !contact ) {
		return null;
	}
	return {
		name: contact.name,
		id: contact.id,
		status: contact.status,
		multidescriptionId: contact.multidescriptionId,
		isAuthenticated: contact.isAuthenticated,
		lastMessageTS: contact.lastMessageTS,
		unreadCount: contact.unreadCount,
		cachedMessages: contact.cachedMessages,
		inviteToken: contact.inviteToken,
		failReason: contact.failReason,
		isExternal: contact.isExternal
	};
}

class RemoteControlServer extends ClientServerRPCObservables {
	constructor( apiUrlBase, connectionId, seedMacKey, seedEncryptionKey, macKey,
		contactService, profileService, groupService, sharedcontactsService, adminService,
		currentUserService, timeService, businessCardService, econfig, registrationId
	) {
		if ( typeof connectionId !== "string" ) {
			throw new Error( "string connectionId required" );
		}

		if ( !( seedEncryptionKey instanceof Key )
			|| ( seedEncryptionKey.kind !== KEY_KINDS.INTERMEDIATE ) ) {
			throw new Error( "Seed Encryption key required" );
		}

		if ( !( seedMacKey instanceof Key )
			|| ( seedMacKey.kind !== KEY_KINDS.INTERMEDIATE ) ) {
			throw new Error( "Seed Mac key required" );
		}

		if ( !( macKey instanceof Key )
			|| ( macKey.kind !== KEY_KINDS.MAC ) ) {
			throw new Error( "Mac key required" );
		}

		if ( !contactService ) {
			throw new Error( "Contact service required" );
		}

		if ( !profileService ) {
			throw new Error( "Profile service required" );
		}

		if ( !groupService ) {
			throw new Error( "Group service required" );
		}

		if ( !sharedcontactsService ) {
			throw new Error( "Sharedcontacts service required" );
		}

		if ( !adminService ) {
			throw new Error( "Admin service required" );
		}

		if ( !currentUserService ) {
			throw new Error( "CurrentUser service required" );
		}

		if ( !timeService || !timeService.monitorTimeResync ) {
			throw new Error( "timeService required" );
		}

		if ( !businessCardService || !businessCardService.observeCards ) {
			throw new Error( "businessCardService required" );
		}

		super(
			apiUrlBase,
			connectionId,
			seedMacKey,
			seedEncryptionKey,
			createSigner( macKey ),
			createVerifier( macKey ),
			econfig,
			registrationId
		);
		this._contactService = contactService;
		this._profileService = profileService;
		this._groupService = groupService;
		this._sharedcontactsService = sharedcontactsService;
		this._adminService = adminService;
		this._currentUserService = currentUserService;
		this._timeService = timeService;
		this._businessCardService = businessCardService;
		this._activitySubj = new Rx.BehaviorSubject( +new Date );
		this._messagesObservables = Object.create( null );
		this._inviteId2String = Object.create( null );
		this._initRemoteMethods();
		this._mutes = Object.create( null );
		this._mutesCounter = 0;
	}

	_initRemoteMethods( ) {
		this._remoteMethods.inviteContact = this._remote_inviteContact;
		this._remoteMethods.getInviteData = this._remote_getInviteData;
		this._remoteMethods.acceptInvite = this._remote_acceptInvite;
		this._remoteMethods.renameContact = this._remote_renameContact;
		this._remoteMethods.deleteContact = this._remote_deleteContact;
		this._remoteMethods.sendMessage = this._remote_sendMessage;
		this._remoteMethods.sendContacts = this._remote_sendContacts;
		this._remoteMethods.createGroup = this._remote_createGroup;
		this._remoteMethods.exitGroup = this._remote_exitGroup;
		this._remoteMethods.updateProfile = this._remote_updateProfile;
		this._remoteMethods.createFakeAccount = this._remote_createFakeAccount;
		this._remoteMethods.changePassword = this._remote_changePassword;
		this._remoteMethods.clearHistory = this._remote_clearHistory;
		this._remoteMethods.clearRemoteHistory = this._remote_clearRemoteHistory;
		this._remoteMethods.setReadAll = this._remote_setReadAll;
		this._remoteMethods.queryHistory = this._remote_queryHistory;
		this._remoteMethods.addHelperContact = this._remote_addHelperContact;
		this._remoteMethods.addSharedContactList = this._remote_addSharedContactList;
		this._remoteMethods.exitMultidescription = this._remote_exitMultidescription;
		this._remoteMethods.removeWorkgroupParticipant = this._remote_removeWorkgroupParticipant;
		this._remoteMethods.removeWorkgroupInvite = this._remote_removeWorkgroupInvite;
		this._remoteMethods.addToWorkGroup = this._remote_addToWorkGroup;
		this._remoteMethods.setWorkgroupRights = this._remote_setWorkgroupRights;
		this._remoteMethods.shareContacts = this._remote_shareContacts;
		this._remoteMethods.deleteGroupParticipant = this._remote_deleteGroupParticipant;
		this._remoteMethods.addGroupParticipants = this._remote_addGroupParticipants;
		this._remoteMethods.addGroupParticipantExternal = this._remote_addGroupParticipantExternal;
		this._remoteMethods.deleteGroupInvite = this._remote_deleteGroupInvite;
		this._remoteMethods.addSession = this._remote_addSession;
		this._remoteMethods.deleteAccount = this._remote_deleteAccount;
		this._remoteMethods.acceptInviteByInviteId = this._remote_acceptInviteByInviteId;
		this._remoteMethods.joinGroupByInviteId = this._remote_joinGroupByInviteId;
		this._remoteMethods.joinWorkgroupByInviteId = this._remote_joinWorkgroupByInviteId;
		this._remoteMethods.addUser = this._remote_addUser;
		this._remoteMethods.editUser = this._remote_editUser;
		this._remoteMethods.deleteUser = this._remote_deleteUser;
		this._remoteMethods.sendDeleteMessage = this._remote_sendDeleteMessage;
		this._remoteMethods.sendEditTextMessage = this._remote_sendEditTextMessage;
		this._remoteMethods.getPrivateServerConfig = this._remote_getPrivateServerConfig;
		this._remoteMethods.muteContactNotification = this._remote_muteContactNotification;
		this._remoteMethods.unmuteContactNotification = this._remote_unmuteContactNotification;
		this._remoteMethods.sendContactsToUser = this._remote_sendContactsToUser;
		this._remoteMethods.businessCardCreate = this._remote_businessCardCreate;
		this._remoteMethods.businessCardDelete = this._remote_businessCardDelete;
		this._remoteMethods.businessCardUpdate = this._remote_businessCardUpdate;

		this._remoteObservableMethods.observeContactList = this._remote_observeContactList;
		this._remoteObservableMethods.observeGroupList = this._remote_observeGroupList;
		this._remoteObservableMethods.observeContactMessages = this._remote_observeContactMessages;
		this._remoteObservableMethods.observeGroupMessages = this._remote_observeGroupMessages;
		this._remoteObservableMethods.observeProfile = this._remote_observeProfile;
		this._remoteObservableMethods.observeChats = this._remote_observeChats;
		this._remoteObservableMethods.observeSharedContacts = this._remote_observeSharedContacts;
		this._remoteObservableMethods.observeSelfRights = this._remote_observeSelfRights;
		this._remoteObservableMethods.observeWorkgroupParticipants = this._remote_observeWorkgroupParticipants;
		this._remoteObservableMethods.observeWorkgroupRights = this._remote_observeWorkgroupRights;
		this._remoteObservableMethods.observeWorkgroupInvites = this._remote_observeWorkgroupInvites;
		this._remoteObservableMethods.observeGroupParticipants = this._remote_observeGroupParticipants;
		this._remoteObservableMethods.observeGroupInvites = this._remote_observeGroupInvites;
		this._remoteObservableMethods.observeUserTable = this._remote_observeUserTable;
		this._remoteObservableMethods.observeGlobalUserType = this._remote_observeGlobalUserType;
		this._remoteObservableMethods.observeCommonData = this._remote_observeCommonData;
		this._remoteObservableMethods.observeNewMessages = this._remote_observeNewMessages;
		this._remoteObservableMethods.observeBusinessCards = this._remote_observeBusinessCards;
	}

	_remote_inviteContact( { contactName, selfNameOverride, isExternal } ) {
		this._activitySubj.onNext( +new Date );
		if ( selfNameOverride === undefined ) {
			return (
				this._profileService.getProfileAsync()
					.flatMap( ( { nickname } ) =>
						this._contactService.createContactAndInviteAsync( contactName, nickname, isExternal )
					)
					.flatMap( contact =>
						this._mapContactAsync( this._contactService, contact )
					)
			);
		}
		return (
			this._contactService
				.createContactAndInviteAsync( contactName, selfNameOverride, isExternal )
				.flatMap( contact =>
					this._mapContactAsync( this._contactService, contact )
				)
		);
	}

	_remote_observeContactList( ) {
		//TODO: remove
		throw new Error( "Not implemented" );
		this._activitySubj.onNext( +new Date );
		let currentContacts = Object.create( null );
		return (
			this._contactService.observeContactList()
				// .debounce( 100 )
				.map( contactList =>
					_.map(
						_.filter( contactList, { multidescriptionId: -1 } ),
						mapContactInList
					)
				)
				.map( contacts => this._compactContacts( currentContacts, contacts ) )
				.filter( diff => !_.isEmpty( diff ) )
		);
	}

	_compactContacts( currentContacts, contacts ) {
		let diff = [];
		let ids = Object.create( null );

		for ( var i = 0; i < contacts.length; i++ ) {
			var contact = contacts[ i ];
			var json = JSON.stringify( contact );
			if ( json !== currentContacts[ contact.id ] ) {
				diff.push( contact );
				currentContacts[ contact.id ] = json;
			}
			ids[ contact.id ] = 1;
		}
		for ( var id in currentContacts ) {
			if ( !ids[ id ] ) {
				delete currentContacts[ id ];
				diff.push( { id } );
			}
		}
		return diff;
	}

	_remote_observeGroupList( ) {
		//TODO: remove
		throw new Error( "Not implemented" );
		this._activitySubj.onNext( +new Date );
		let currentContacts = Object.create( null );
		return (
			this._groupService.observeContactList()
				// .debounce( 100 )
				.map( contactList =>
					_.map(
						contactList,
						mapContactInList
					)
				)
				.map( contacts => this._compactContacts( currentContacts, contacts ) )
				.filter( diff => !_.isEmpty( diff ) )
		);
	}

	_remote_getInviteData( { token } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._contactService.receiveInviteAsync( token )
				.map( inviteData => {
					inviteData.tmpPrivateKey && inviteData.tmpPrivateKey.dispose();
					inviteData.seedKey && inviteData.seedKey.dispose();
					inviteData.creatorPublicKey && inviteData.creatorPublicKey.dispose();
					return { nickname: inviteData.nickname, config: inviteData.config };
				} )
				.catch( () => Rx.Observable.just( null ) )
		);
	}

	_remote_acceptInvite( { token, name } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._contactService.receiveInviteAsync( token )
				.flatMap( inviteData =>
					this._contactService.acceptInviteAsync( inviteData, name )
				)
				.catch( error => {
					console.error( "_remote_acceptInvite caught error", error );
					return Rx.Observable.just( false );
				} )
				.flatMap( contact => contact
					? this._contactService.ensureLoadedDetailsAsync( contact )
						.flatMap( contact =>
							this._mapContactAsync( this._contactService, contact )
						)
					: Rx.Observable.just( contact )
				)
		);
	}

	_remote_renameContact( {contactId, contactType, newName} ) {
		this._activitySubj.onNext( +new Date );
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			case "multidescription":
				service = this._sharedcontactsService;
				break;
		}
		newName = service.getUnconflictedName( newName );
		return service.updateAsync( contactId, {name: newName} );
	}

	_remote_deleteContact( {contactId} ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._contactService.deleteContactAsync( contactId )
		);
	}

	_remote_sendMessage( {contactId, contactType, message} ) {
		this._activitySubj.onNext( +new Date );
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			default:
				throw new Error( "Invalid contact type" );
		}
		let startAt = +new Date;
		return (
			Rx.Observable.combineLatest(
				service.ensureLoadedDetailsAsync( contactId ),
				message.id
				? Rx.Observable.just( message.id )
				: Rx.Observable.fromPromise( ssgCrypto.createRandomBase64StringThen( 32 ) ),
				( contact, id ) => ( { contact, id } )
			)
				.flatMap( ( { contact, id } ) => {
					return (
						service._sendMessageAsync( contact, { id, ...message } )
					);
				} )

		);
	}

	_remote_sendContacts( {contactId, contactIdsToSend} ) {
		this._activitySubj.onNext( +new Date );
		return this._contactService.sendContactsAsync( contactId, _.toArray( contactIdsToSend ) );
	}

	_compactMessagesObservable( isGroup, contactId, observable ) {
		this._activitySubj.onNext( +new Date );
		let currentMessageList = Object.create( null );
		let isSentAny = false;

		return Rx.Observable.combineLatest(
			observable,
			this._contactService.observeContactList(),
			this._groupService.observeContactList(),
			this._sharedcontactsService.observeContactList(),
			( messages, contacts, groups, workgroups ) =>
				( { messages, contacts, groups, workgroups } )
		)//.debounce( 100 )
		.map( ( { messages, contacts, groups, workgroups } ) => {
			let diff = Object.create( null );
			for( let indexStr in messages ) {
				let message = messages[ indexStr ];
				let {id} = message;
				let convertedMessage = this.convertMessage( message, contacts, groups, workgroups );
				convertedMessage.sender =
					( isGroup ? this._groupService : this._contactService )
						.getParticipantNameOrNull( contactId, message.senderPid );

				if (message.replyTo && message.replyTo.replySenderPid) {
					convertedMessage.replyingSender = ( isGroup ? this._groupService : this._contactService )
							.getParticipantNameOrNull( contactId, message.replyTo.replySenderPid );
				}

				let json = JSON.stringify( convertedMessage );

				if ( currentMessageList[ indexStr ] !== json ) {
					currentMessageList[ indexStr ] = json;
					diff[ indexStr ] = convertedMessage;
				}
			}
			for ( let indexStr in currentMessageList ) {
				if ( _.isEmpty( messages[ indexStr ] ) ) {
					delete currentMessageList[ indexStr ];
					diff[ indexStr ] = null;
				}
			}
			return diff;
		} )
		.filter( diff => {
			return ( !isSentAny || !_.isEmpty( diff ) ) && ( isSentAny = true );
		} );
	}

	convertMessage( message, contacts, groups, workgroups ) {
		let json = message.toJson();
		let inviteString;
		switch ( json.contentType ) {
			case "text/plain":
				return json;
			case "contact":
				inviteString = json.contactInviteDataString;
				delete json.contactInviteDataString;
				break;
			case "group":
				inviteString = json.groupInviteDataString;
				delete json.groupInviteDataString;
				break;
			case "workgroup":
				inviteString = json.workgroupInviteDataString;
				delete json.workgroupInviteDataString;
				break;
			default:
				return json;
		}
		let buffer = new Buffer( inviteString, "base64" );
		let inviteData = deserializeObject( buffer );

		json.inviteId = ssgCrypto.hash( buffer ).toString();
		json.name = inviteData.name;
		json.nickname = inviteData.nickname;

		let findFunc;
		if ( inviteData.globalId ) {
			findFunc = ( { sharedId, globalId } ) => ( sharedId.equals( inviteData.sharedId ) )
				|| ( globalId && globalId.equals( inviteData.globalId ) );
		} else {
			findFunc = ( { sharedId, globalId } ) => ( sharedId.equals( inviteData.sharedId ) );
		}
		json.isDuplicate = !!(_.find( contacts, findFunc ) || _.find( groups, findFunc ) || _.find( workgroups, findFunc ) );
		this._inviteId2String[ json.inviteId ] = inviteString;
		return json;
	}

	_remote_acceptInviteByInviteId( { inviteId, multidescriptionId } ) {
		let inviteString = this._inviteId2String[ inviteId ];
		if ( !inviteString ) {
			return Rx.Observable.just( false );
		}
		return (
			this._contactService.acceptInviteIfNotJoinedAsync(
				inviteString,
				multidescriptionId
			)
				.map( () => true )
		);
	}

	_remote_joinGroupByInviteId( { inviteId } ) {
		let inviteString = this._inviteId2String[ inviteId ];
		if ( !inviteString ) {
			return Rx.Observable.just( false );
		}
		return (
			this._groupService.acceptInviteIfNotJoinedAsync( inviteString )
				.map( () => true )
		);
	}

	_remote_joinWorkgroupByInviteId( { inviteId } ) {
		let inviteString = this._inviteId2String[ inviteId ];
		if ( !inviteString ) {
			return Rx.Observable.just( false );
		}
		return (
			this._sharedcontactsService.acceptWorkGroupInviteIfNotJoinedAsync(
				inviteString
			)
			.map( () => true )
		);
	}

	_remote_observeContactMessages( { contactId } ) {
		this._activitySubj.onNext( +new Date );
		return this._compactMessagesObservable( false, contactId,
			this._contactService.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact => {
					if ( !contact ) {
						//Deleted
						return Rx.Observable.empty();
					}
					if ( !this._messagesObservables[ contactId ] ) {
						this._messagesObservables[ contactId ] = [];
					}
					let observable = this._contactService.observeMessages( contact );
					this._messagesObservables[ contactId ].push( observable );
					this._contactService.requestMoreHistory( contactId, observable );

					return (
						observable
							.finally( () => {
								let index = this._messagesObservables[ contactId ].indexOf( observable );
								this._messagesObservables[ contactId ].splice( index, 1 );
							} )
					);
				} )
		);
	}

	_remote_observeGroupMessages( { contactId } ) {
		this._activitySubj.onNext( +new Date );
		return this._compactMessagesObservable( true, contactId,
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact => {
					if ( !this._messagesObservables[ contactId ] ) {
						this._messagesObservables[ contactId ] = [];
					}
					let observable = this._groupService.observeMessages( contact );
					this._messagesObservables[ contactId ].push( observable );
					this._groupService.requestMoreHistory( contactId, observable );
					return (
						observable
							.finally( () => {
								let index = this._messagesObservables[ contactId ].indexOf( observable );
								this._messagesObservables[ contactId ].splice( index, 1 );
							} )
					);
				} )
		);
	}

	_remote_createGroup( { name, nickname, contactIds } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._groupService.createGroupWithParticipantsAsync(
				name,
				nickname,
				_.toArray( contactIds ),
				this._contactService
			)
			.flatMap( contact =>
				this._mapContactAsync( this._groupService, contact )
			)
		);
	}

	_remote_observeGroupParticipants( { contactId } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._groupService.observeParticipants( contactId )
				.map( ps => _.mapValues( ps, ({nickname}) => ({nickname}) ) )
		);
	}

	_remote_observeGroupInvites( { contactId } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._groupService.observeInvites( contactId )
				.map( ps => _.mapValues( ps, ( { nickname } ) => ( { nickname } ) ) )
		);
	}

	_remote_renameGroupParticipant( { contactId, pid, newNickname } ) {
		this._activitySubj.onNext( +new Date );
		return Rx.Observable.throw( new Error( "Not implemented" ) );
	}

	_remote_deleteGroupParticipant( { contactId, pid } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact =>
					this._groupService.deleteParticipantAsync( contact, pid )
				)
		);
	}

	_remote_deleteGroupInvite( { contactId, pid } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact =>
					this._groupService.deleteInviteAsync( contact, pid )
				)
		);
	}

	_remote_addGroupParticipants( { contactId, contactIds } ) {
		this._activitySubj.onNext( +new Date );
		contactIds = _.toArray( contactIds );
		return (
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( group =>
					Rx.Observable.fromArray( contactIds )
						.flatMap( id => this._contactService.getContactsAsync()
							.map( contacts => _.find( contacts, { id } ) )
						)
						.filter( contact => !!contact ) //skip if not found
						.flatMap( contact =>
							this._groupService.createInviteOrNullAsync( group, contact.name )
								.flatMap( invite => invite
									? this._contactService.sendInviteToGroupAsync( contact, invite )
									: Rx.Observable.just( null )
								)
						)
					.toArray()
				)
		);
	}

	_remote_addGroupParticipantExternal( { contactId, nickname } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._groupService.ensureLoadedDetailsAsync( contactId )
				.flatMap( group => this._groupService.createExternalInviteAsync( group, nickname ) )
		);
	}

	_remote_observeProfile( ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._profileService.mutationObservable
				.filter( profile => !!profile )
				.map( profile => {
					//Skip sensitive data
					let { backup, activation, admin, ...profileData } = profile;
					return profileData;
				} )
		);
	}

	_remote_exitGroup( {id} ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._groupService.ensureLoadedDetailsAsync( id )
				.flatMap( contact => this._groupService.deleteGroupAsync( contact ) )
		);
	}

	_remote_exitMultidescription( {id} ) {
		this._activitySubj.onNext( +new Date );
		return this._sharedcontactsService.deleteContactAsync( id );
	}

	_remote_updateProfile( {profileChanges} ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._profileService.updateProfileAsync( profileChanges )
		);
	}

	_remote_observeChats( ) {
		//TODO: remove
		throw new Error( "Not implemented" );
		this._activitySubj.onNext( +new Date );
		let currentContacts = Object.create( null );
		return Rx.Observable.combineLatest(
			this._contactService.observeContactsWithMessages(),
			this._groupService.observeContactsWithMessages(),
			( contacts, groups ) =>
				this._compactContacts( currentContacts,
					_.map( contacts, contact => {
						let mappedContact = mapContactInList( contact );
						mappedContact.type = "normal";
						return mappedContact;
					} )
						.concat(
							_.map( groups, contact => {
								let mappedContact = mapContactInList( contact );
								mappedContact.type = "group";
								return mappedContact;
							} )
						)
				)
		).filter( diff => !_.isEmpty( diff ) );
	}

	_remote_createFakeAccount( {name, password} ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._currentUserService.tryGetMasterKey( password )
				.flatMap( masterKey => {
					if ( masterKey ) {
						return Rx.Observable.just( false );
					}
					return (
						this._currentUserService.registerNewAsync( name, password )
							.map( () => true )
					);
				} )
		);

	}


	_remote_changePassword( {password} ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._currentUserService.tryGetMasterKey( password )
				.flatMap( masterKey => {
					if ( masterKey ) {
						return Rx.Observable.just( false );
					}
					return (
						this._currentUserService.changePasswordAsync( password )
							.map( () => true )
					);
				} )
		);
	}

	_remote_clearHistory( {contactType, contactId} ) {
		this._activitySubj.onNext( +new Date );
		if ( !contactType ) {
			this._contactService.clearHistory();
			this._groupService.clearHistory();
			return;
		}
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
		}
		service.clearContactHistory( contactId );
		return Rx.Observable.just( true );
	}

	_remote_clearRemoteHistory( { contactId } ) {
		this._activitySubj.onNext( +new Date );
		return (
			this._contactService.askParticipantsToCleanHistoryAsync( contactId )
				.map( () => true )
		);
	}

	observeActivity( ) {
		return this._activitySubj;
	}

	_remote_setReadAll( {contactType, contactId} ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
		}
		return (
			service.getDetailedContactAsync( contactId )
				.map( contact => {
					if ( contact ) {
						service.setReadAll( contact );
					}
					return {};
				} )
		);
	}

	_remote_queryHistory( { contactType, contactId } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
		}
		let messagesObservables = this._messagesObservables[ contactId ];
		if ( !messagesObservables ) {
			return Rx.Observable.just( false );
		}
		return (
			service.getDetailedContactAsync( contactId )
				.map( contact => {
					messagesObservables.forEach( messagesObservable => {
						service.requestMoreHistory( contactId, messagesObservable )
					} );
					return true;
				} )
		);
	}

	_remote_observeSharedContacts( ) {
		//TODO: remove
		throw new Error( "Not implemented" );
		let currentContacts = Object.create( null );
		let sent = Object.create( null );
		let isSentAny = false;
		return (
			Rx.Observable.combineLatest(
				this._sharedcontactsService.observeContactList(),
				this._contactService.observeContactList(),
				( multidescriptions, contacts ) =>
					_.map( multidescriptions, multidescription => ( {
						sharedList: this._compactContacts(
							currentContacts[ multidescription.id ] = currentContacts[ multidescription.id ] || Object.create( null ),
							_.map( _.filter(
								contacts,
								{ multidescriptionId: multidescription.id }
							), mapContactInList )
						),
						name: multidescription.name,
						id: multidescription.id
					} ) )
			)
			.filter( diffs =>
				!isSentAny || _.some( diffs, ( { sharedList, id } ) =>
					!sent[ id ] || !_.isEmpty( sharedList ) )
			)
			.tap( diffs => {
				_.forEach( diffs, ( { id } ) => { sent[ id ] = true; } );
				isSentAny = true;
			} )
		);
	}

	_remote_observeCommonData() {
		let currentShared = Object.create( null );
		let sentShared = Object.create( null );
		let currentContacts = Object.create( null );
		let currentGroups = Object.create( null );
		let prevIsAdmin = null;
		let prevIsPrivileged = null;
		let prevProfile = null;
		let prevProfileStr = null;
		let prevTimeDiff = null;
		return (
			Rx.Observable.combineLatest(
				this._sharedcontactsService.observeContactList(),
				this._observeContactListWithAutocleanTime( this._contactService ),
				this._observeContactListWithAutocleanTime( this._groupService ),
				this._adminService.observeIsAdmin(),
				this._adminService.observeIsPrivileged(),
				this._currentUserService.observeExpireDt().startWith(null),
				this._remote_observeProfile(),
				this._timeService.monitorTimeResync(),
				( multidescriptions, contacts, groups, isAdmin, isPrivileged, expireDt, profile, timeDiff ) => ( {
					timeDiff,
					serverTimeStamp: +new Date,
					m: _.map( multidescriptions, multidescription => ( {
						sharedList: this._compactContacts(
							currentShared[ multidescription.id ] = currentShared[ multidescription.id ] || Object.create( null ),
							_.filter(
								contacts,
								{ multidescriptionId: multidescription.id }
							)
						),
						name: multidescription.name,
						id: multidescription.id
					} ) ),
					c: this._compactContacts(
						currentContacts,
						_.filter( contacts, { multidescriptionId: -1 } )
					),
					g: this._compactContacts(
						currentGroups,
						groups
					),
					isAdmin, isPrivileged, expireDt,
					p: ( profile === prevProfile || prevProfileStr === JSON.stringify( profile ) ) ? null : profile
				} )
			)
			.filter( ( { m, c, g, isAdmin, isPrivileged, p, timeDiff } ) => {
				if ( timeDiff !== prevTimeDiff ) {
					return true;
				}
				if ( ( isAdmin !== prevIsAdmin ) || ( isPrivileged !== prevIsPrivileged ) ) {
					return true;
				}
				if ( !_.isEmpty( c ) || !_.isEmpty( g ) || _.some( m,
					( { id, sharedList } ) => !sentShared[ id ] || !_.isEmpty( sharedList )
				) ) {
					return true;
				}
				if ( p ) {
					return true;
				}
				return false;
			} )
			.tap( ( { isAdmin, isPrivileged, p, timeDiff } ) => {
				prevIsAdmin = isAdmin;
				prevIsPrivileged = isPrivileged;
				prevTimeDiff = timeDiff;
				if ( p ) {
					prevProfile = p;
					prevProfileStr = JSON.stringify( p );
				}
			} )
		);
	}

	_observeContactListWithAutocleanTime( service ) {
		return (
			service.observeContactList()
				.concatMap( contacts =>
					Rx.Observable.fromArray( contacts )
						.concatMap( contact => this._mapContactAsync( service, contact ) )
						.toArray()
				)
		);
	}

	_mapContactAsync( service, contact ) {
		if ( !contact.hasDetails() ) {
			return Rx.Observable.just( mapContactInList( contact ) );
		}
		return (
			service.observeAutocleanTime( contact.id )
				.take( 1 ).map( autocleanTime => {
					let contactMapped = mapContactInList( contact );
					contactMapped.autocleanTime = autocleanTime;
					return contactMapped;
				} )
		);
	}

	_remote_addHelperContact( { name } ) {
		return this._contactService.addHelperContactAsync( name );
	}

	_remote_addSharedContactList( {nickname, name, workgroupIds, contactIds, rights} ) {
		if ( !nickname || ( typeof nickname !== "string" ) ) {
			return Rx.Observable.throw( new Error( "String nickname required" ) );
		}
		if ( !name || ( typeof name !== "string" ) ) {
			return Rx.Observable.throw( new Error( "String name required" ) );
		}
		workgroupIds = _.toArray( workgroupIds );
		contactIds = _.toArray( contactIds );
		if ( !contactIds.length ) {
			return Rx.Observable.throw( new Error( "contactIds required" ) );
		}
		if ( !rights ) {
			return Rx.Observable.throw( new Error( "rights required" ) );
		}
		return (
			this._sharedcontactsService.createSharedGroupAsync(
				name, nickname, workgroupIds, contactIds, rights
			)
		);
	}

	_remote_observeSelfRights( {contactId} ) {
		return (
			this._sharedcontactsService.observeSelfRights( contactId )
		);
	}

	_remote_observeWorkgroupParticipants( {contactId} ) {
		return (
			this._sharedcontactsService.observeParticipants( contactId )
				.map( ps => _.mapValues( ps, ({nickname}) => ({nickname}) ) )
		);
	}

	_remote_observeWorkgroupInvites( {contactId, pid} ) {
		return (
			this._sharedcontactsService.observeInvites( contactId, pid )
				.map( ps => _.mapValues( ps, ({nickname}) => ({nickname}) ) )
		);
	}

	_remote_observeWorkgroupRights( {contactId} ) {
		return (
			this._sharedcontactsService.observeRights( contactId )
		);
	}

	_remote_removeWorkgroupParticipant( {contactId, pid} ) {
		return (
			this._sharedcontactsService.removeParticipantAsync( contactId, pid )
		);
	}

	_remote_removeWorkgroupInvite( {contactId, pid} ) {
		return (
			this._sharedcontactsService.removeWorkgroupInviteAsync( contactId, pid )
		);
	}

	_remote_addToWorkGroup( {multidescriptionId, contactId} ) {
		return (
			this._sharedcontactsService.addToWorkGroupAsync( multidescriptionId, contactId )
				.map( () => null )
		);
	}

	_remote_setWorkgroupRights( {multidescriptionId, modifiedRights} ) {
		modifiedRights = _.toArray( modifiedRights );
		return (
			this._sharedcontactsService.setRightsAsync( multidescriptionId, modifiedRights )
				.map( () => null )
		);
	}

	_remote_shareContacts( {contactIds, multidescriptionId} ) {
		contactIds = _.toArray( contactIds );
		return (
			this._sharedcontactsService.shareContactsAsync( contactIds, multidescriptionId )
		);
	}

	onAddSession( func ) {
		if ( typeof func !== "function" ) {
			throw new Error( "Function required" );
		}
		this._onAddSession = func;
	}

	_remote_addSession( { sessionDataString } ) {
		let buf = new Buffer( sessionDataString, "base64" );
		let mb = ssgCrypto.createManagedBuffer( buf.length );
		mb.useAsBuffer( b => buf.copy( b ) );

		return (
			deserializeObjectWithKeysFromManagedBufferAsync(
				mb, {
					seedMacKey: KEY_KINDS.INTERMEDIATE,
					seedEncryptionKey: KEY_KINDS.INTERMEDIATE,
					macKey: KEY_KINDS.MAC
				},
				configuration.getDefaultEncryptionConfig()
			)
				.flatMap( ( { connectionId, seedMacKey, seedEncryptionKey, macKey } ) =>
					this._onAddSession( connectionId, seedMacKey, seedEncryptionKey, macKey )
					|| Rx.Observable.just( null )
				).tap( () => {
					this._activitySubj.onNext( +new Date );
				} )
		);
	}

	_remote_deleteAccount( ) {
		return this._currentUserService.dropAccountAsync().tap( () => {
			setTimeout( () => {
				global.window.location.reload();
			} );
		} );
	}

	_remote_observeUserTable( ) {
		return this._adminService.observeUserTable();
	}

	_remote_observeGlobalUserType( ) {
		let prevIsAdmin = null;
		let prevIsPrivileged = null;
		return Rx.Observable.combineLatest(
			this._adminService.observeIsAdmin(),
			this._adminService.observeIsPrivileged(),
			( isAdmin, isPrivileged ) => ( { isAdmin, isPrivileged } )
		).filter( ( { isAdmin, isPrivileged } ) => {
			if ( ( isAdmin === prevIsAdmin ) && ( isPrivileged === prevIsPrivileged ) ) {
				return false;
			}
			prevIsAdmin = isAdmin;
			prevIsPrivileged = isPrivileged;
			return true;
		} );
	}

	_remote_addUser( {user} ) {
		return (
			this._adminService.updateUserTableAsync( [ user ], [], [] )
				.map( () => user )
		);
	}

	_remote_editUser( {user} ) {
		return (
			this._adminService.updateUserTableAsync( [], [ user ], [] )
		);
	}

	_remote_deleteUser( {userId} ) {
		return (
			this._adminService.updateUserTableAsync( [], [], [ userId ] )
		);
	}

	_remote_sendDeleteMessage( { messageIndex, contactId, contactType } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			default:
				throw new Error( "Invalid contact type" );
		}
		return (
			service.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact =>
					service.sendDeleteMessageAsync( contact, messageIndex )
				)
		);
	}

	_remote_sendEditTextMessage( { messageIndex, newText, contactId, contactType } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			default:
				throw new Error( "Invalid contact type" );
		}
		return (
			service.ensureLoadedDetailsAsync( contactId )
				.flatMap( contact =>
					service.sendEditTextMessageAsync( contact, messageIndex, newText )
				)
		);
	}

	_remote_getPrivateServerConfig( ) {
		return Rx.Observable.just( configuration.getPrivateServerConfig() );
	}

	_remote_muteContactNotification( { contactId, contactType } ) {
		let service;
		switch( contactType ) {
			case "normal":
				service = this._contactService;
				break;
			case "group":
				service = this._groupService;
				break;
			default:
				throw new Error( "Invalid contact type" );
		}
		let muteId = ++this._mutesCounter;
		this._mutes[ muteId ] = service.mute( contactId );
		return Rx.Observable.just( { muteId } );
	}

	_remote_unmuteContactNotification( { muteId } ) {
		if ( !this._mutes[ muteId ] ) {
			console.error( "Mute not found" );
			return Rx.Observable.just( {} );
		}

		this._mutes[ muteId ].dispose();
		delete this._mutes[ muteId ];
		return Rx.Observable.just( {} );
	}

	_remote_observeNewMessages() {
		return Rx.Observable.merge(
			this._contactService.observeNewMessages(),
			this._groupService.observeNewMessages()
		);
	}
	_remote_sendContactsToUser( { userId, userIds } ) {
		return this._adminService.sendContactsToUserAsync( userId, _.toArray( userIds ) );
	}

	_remote_businessCardCreate( { name, description } ) {
		return Rx.Observable.fromPromise(
			this._businessCardService.createCardThen( name, description )
		);
	}

	_remote_businessCardDelete( { token } ) {
		return Rx.Observable.fromPromise(
			this._businessCardService.deleteCardThen( token )
		);
	}

	_remote_businessCardUpdate( { token, name, description } ) {
		return Rx.Observable.fromPromise(
			this._businessCardService.updateCardThen( token, name, description )
		);
	}

	_remote_observeBusinessCards() {
		return this._businessCardService.observeCards();
	}

	dispose( ) {
		this._activitySubj.onCompleted();
		super.dispose();
	}
}

export default RemoteControlServer;
